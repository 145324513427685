import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Crops",
      "search-by": "Code,Name,CropFamilyName",
      "ph-search-by": "Search by Crops Code, Crops Name and Crops Family"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("div", {
          staticClass: "text-center"
        }, [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1)], 1)];
      }
    }])
  }, [_c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Crops Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Name",
    attrs: {
      "data-index": "name",
      "title": "Crops Name",
      "sorter": true
    }
  }), _c("text-field", {
    key: "CropFamilyName",
    attrs: {
      "data-index": "cropFamilyName",
      "title": "Crops Family",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "SampleFlag",
    attrs: {
      "title": "Sample Flag",
      "align": "center",
      "data-index": "sampleFlag",
      "render-icon": _vm.renderIcon,
      "width": 135
    }
  }), _c("icon-field", {
    key: "MultipleSampleType",
    attrs: {
      "title": "Multiple Sample Type",
      "align": "center",
      "data-index": "multipleSampleType",
      "render-icon": _vm.renderIcon,
      "width": 135
    }
  }), _c("icon-field", {
    key: "GradeByWeight",
    attrs: {
      "title": "Grade By Weight",
      "align": "center",
      "data-index": "gradeByWeight",
      "render-icon": _vm.renderIcon,
      "width": 135
    }
  }), _c("icon-field", {
    key: "Attributes",
    attrs: {
      "title": "Attributes",
      "align": "center",
      "data-index": "attributes",
      "render-icon": _vm.renderIcon,
      "width": 135
    }
  }), _c("icon-field", {
    key: "GradeOverride",
    attrs: {
      "title": "Grade Override",
      "align": "center",
      "data-index": "gradeOverride",
      "render-icon": _vm.renderIcon,
      "width": 135
    }
  })], 1);
};
var staticRenderFns$1 = [];
var ListCrops_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListCrops",
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6cc2ab93", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListCrops = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-crops"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.crops",
      "api-url": _vm.apiUrl,
      "edit-route": "/watties-grower/crops/:id",
      "list": _vm.ListCrops
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Crops",
  data() {
    return {
      ListCrops,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "crops",
          title: "Crops",
          path: "/watties-grower/crops"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
